import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Box, Button, Heading, Image, Text } from 'grommet'
import { CircleQuestion, Alert } from 'grommet-icons'
import { handleFileDrop } from '../actions/dna'
import { signout } from '../actions/user'
import AppLayout from '../components/app-layout'
import DropZone from '../components/drop-zone'

const mapStateToProps = state => ({
  uid: state.user.uid,
  step: (() => {
    if (state.dna.uploadPct === 100) {
      return 4
    } else if (state.dna.uploadPct > 0 && state.dna.uploadURL) {
      return 3
    } else if (state.dna.uploadURL) {
      return 2
    } else if (state.dna.file) {
      return 1
    } else {
      return 0
    }
  })(),
  file: state.dna.file,
  error: state.dna.error,
  uploadPct: state.dna.uploadPct
})

const mapDispatchToProps = dispatch => ({
  onUpload: file => dispatch(handleFileDrop(file)),
  onSignout: () => dispatch(signout())
})

const ImportPage = ({
  step,
  file,
  error,
  uploadPct,
  onUpload,
}) => (
    <AppLayout page="import">
      <Box
        style={{ maxWidth: '64rem', marginLeft: 'auto', marginRight: 'auto' }}
        fill={true}
        pad='xlarge'
      >
        <Heading color='accent-2'>
          securely upload your data
      </Heading>
        <Text
          size='medium'
        >
          We support 23andMe, AncestryDNA and MyHeritage files. If your data is from a different company we'll try our best to read the information.
        <br /><br />
          We prefer <b>.TXT</b> files. If you encounter a problem uploading your zip file, please extract the zip and try again with the text file.
      </Text>
        <Box
          justify='center'
          align='center'
          pad={{ top: 'large', bottom: 'medium' }}
        >
          <DropZone
            onUpload={onUpload}
            step={step}
            file={file}
            uploadPct={uploadPct}
          />
        </Box>
        {
          error &&
          <Box
            background='status-error'
            align='center'
            direction='row'
            pad='small'
            gap='small'
            round='xsmall'
          >
            <Alert size='small' color='white' />
            <Text size='small' color='white'>{error}</Text>
          </Box>
        }
        {
          step === 0 &&
          <Box
            direction='row'
            pad={{ top: 'large', bottom: 'medium' }}
            gap='medium'
            style={{ justifyContent: 'space-evenly' }}
          >
            <a
              style={{ flex: '0 1 auto', height: '4rem' }}
              href='https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-and-Downloading-Your-Raw-Data'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Box
                basis='1/2'
                direction='row'
                align='center'
                justify='center'
                height='4rem'
              >
                <Image
                  style={{ flex: '0 1 auto', height: '4rem' }}
                  fit='contain'
                  src='/images/23andMe.svg'
                />
                <Box
                  direction='row'
                  align='flex-start'
                  justify='center'
                  height='4rem'
                >
                  <CircleQuestion size='medium' />
                </Box>
              </Box>
            </a>

            <a
              style={{ flex: '0 1 auto', height: '4rem' }}
              href='https://support.ancestry.com/s/article/Downloading-Raw-DNA-Data-1460089696533'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Box
                basis='1/2'
                direction='row'
                align='center'
                justify='center'
                height='4rem'
              >
                <Image
                  style={{ flex: '0 1 auto', height: '2rem' }}
                  fit='contain'
                  src='/images/ancestry.svg'
                />
                <Box
                  direction='row'
                  align='flex-start'
                  justify='center'
                  height='4rem'
                >
                  <CircleQuestion size='medium' />
                </Box>
              </Box>
            </a>

            <a
              style={{ flex: '0 1 auto', height: '4rem' }}
              href='https://faq.myheritage.com/en/article/how-do-i-download-my-raw-dna-data-file-from-myheritage'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Box
                basis='1/2'
                direction='row'
                align='center'
                justify='center'
                height='4rem'
              >
                <Image
                  style={{ flex: '0 1 auto', height: '2rem' }}
                  fit='contain'
                  src={require('../images/myheritage-orange.svg')}
                />
                <Box
                  direction='row'
                  align='flex-start'
                  justify='center'
                  height='4rem'
                >
                  <CircleQuestion size='medium' />
                </Box>
              </Box>
            </a>
          </Box>
        }
        <Box
          direction='row'
          pad={{ top: 'xlarge' }}
          align='center'
          justify='end'
        >
          <Link to={step === 4 ? '/complete' : '/import'}>
            <Button
              onClick={step === 4 ? () => console.log('Click!') : null}
              label='Finish'
              color='accent-1'
              primary
            />
          </Link>
        </Box>
      </Box>
    </AppLayout>
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportPage)
