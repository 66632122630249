import React from 'react'
import Dropzone from 'react-dropzone'
import { Box, Button, Text, Meter } from 'grommet'
import {
  Upload,
  Secure,
  DocumentZip,
  DocumentTxt,
  DocumentCsv,
  DocumentUpload,
  StatusGood,
} from 'grommet-icons'

const DropZone = ({
  onUpload,
  step,
  file,
  uploadPct
}) => (
  <Dropzone
    style={{ cursor: 'pointer' }}
    onDrop={acceptedFiles => {
      acceptedFiles.forEach(file => {
        onUpload(file)
      })
    }}
    multiple={false}
    disabled={step !== 0}
    disablePreview
  >
    {({ getRootProps, getInputProps }) => (
      <Box
        animation='fadeIn'
        elevation='small'
        justify='center'
        align='center'
        border={{ size: 'small', color: 'light-4' }}
        round='medium'
        height='medium'
        width='auto'
        pad='medium'
        style={{ borderStyle: 'dashed' }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {
          [
            <Step0 />,
            <Step1 file={file} />,
            <Step2 file={file} />,
            <Step3 file={file} uploadPct={uploadPct} />,
            <Step4 file={file} uploadPct={uploadPct} />
          ][step]
        }
      </Box>
  )}
  </Dropzone>
)

export default DropZone

const Step0 = () => (
  <>
    <Secure size='small' color='neutral-3' style={{ position: 'absolute', top: 0, right: 0, margin: '16px' }} />
    <Upload size='large' color='brand' />
    <Text
      margin={{ top: 'large' }}
      weight='bold'
      size='medium'
      textAlign='center'
    >
      Drag and drop your DNA file here
    </Text>
    <Text
      tag='i'
      margin={{ vertical: 'medium' }}
      size='small'
      textAlign='center'
    >
      or
    </Text>
    <div>
      <Button
        onClick={() => console.log('Click!')}
        label='Browse files'
        color='accent-1'
        primary
        fill
      />
    </div>
  </>
)

const Step1 = ({
  file
}) => (
  <>
    {
      (file.name.endsWith('txt') && <DocumentTxt size='xlarge' color='brand' />) ||
      (file.name.endsWith('zip') && <DocumentZip size='xlarge' color='brand' />) ||
      (file.name.endsWith('csv') && <DocumentCsv size='xlarge' color='brand' />) ||
      <DocumentUpload size='xlarge' color='brand' />
    }
    <Text
      tag='i'
      margin={{ vertical: 'medium' }}
      size='small'
      textAlign='center'
    >
      Preparing upload...
    </Text>
    <Box
      margin='small'
    >
      <Meter
        background='light-2'
        round
        size='full'
        thickness='small'
        type='bar'
        values={[
          {
            color: 'brand',
            label: 'Preparing upload...',
            value: 0.1
          }
        ]}
      />
    </Box>
  </>
)

const Step2 = ({
  file,
  uploadPct
}) => (
  <>
    {
      (file.name.endsWith('txt') && <DocumentTxt size='xlarge' color='brand' />) ||
      (file.name.endsWith('zip') && <DocumentZip size='xlarge' color='brand' />) ||
      (file.name.endsWith('csv') && <DocumentCsv size='xlarge' color='brand' />) ||
      <DocumentUpload size='xlarge' color='brand' />
    }
    <Text
      tag='i'
      margin={{ vertical: 'medium' }}
      size='small'
      textAlign='center'
    >
      Starting upload...
    </Text>
    <Box
      margin='small'
    >
      <Meter
        background='light-2'
        round
        size='full'
        thickness='small'
        type='bar'
        values={[
          {
            color: 'brand',
            label: 'Uploading...',
            value: 1
          }
        ]}
      />
    </Box>
  </>
)

const Step3 = ({
  file,
  uploadPct
}) => (
  <>
    {
      (file.name.endsWith('txt') && <DocumentTxt size='xlarge' color='brand' />) ||
      (file.name.endsWith('zip') && <DocumentZip size='xlarge' color='brand' />) ||
      (file.name.endsWith('csv') && <DocumentCsv size='xlarge' color='brand' />) ||
      <DocumentUpload size='xlarge' color='brand' />
    }
    <Text
      tag='i'
      margin={{ vertical: 'medium' }}
      size='small'
      textAlign='center'
    >
      Uploading ({uploadPct.toFixed(2)}%)...
    </Text>
    <Box
      margin='small'
    >
      <Meter
        background='light-2'
        round
        size='full'
        thickness='small'
        type='bar'
        values={[
          {
            color: 'brand',
            label: 'Uploading...',
            value: uploadPct
          }
        ]}
      />
    </Box>
  </>
)

const Step4 = ({
  file,
  uploadPct
}) => (
  <>
    <StatusGood size='xlarge' color='status-ok' />
    <Text
      tag='i'
      margin={{ vertical: 'medium' }}
      size='small'
      textAlign='center'
    >
      Finished uploading!
    </Text>
    <Box
      margin='small'
    >
      <Meter
        background='light-2'
        round
        size='full'
        thickness='small'
        type='bar'
        values={[
          {
            color: 'brand',
            label: 'Uploading...',
            value: uploadPct
          }
        ]}
      />
    </Box>
  </>
)
